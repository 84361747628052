import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Favoritos/main")
)

//Components

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  return (
    <Layout>
      <Seo title="Favoritos" noFollow />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSideOnlyLazy />
        </React.Suspense>
      )}
    </Layout>
  )
}

export default IndexPage
